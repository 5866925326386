<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="brandModel" :items="brandLists" default="" item-value="brand_id" item-text="brand_id" label="Select Brand" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="divisionModel" :items="divisionLists" default="" item-value="class" item-text="descr" label="Select Division" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" dense label="Start Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" dense label="End Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph2">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph3">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer3" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph4">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer4" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph5">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer5" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph6">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer6" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph7">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer7" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 cardGraph8">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer8" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            brandModel: '',
            brandLists: [],
            divisionModel: '',
            divisionLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false
            
        }
    },
    async mounted(){
        
        this.initialize()
        document.querySelector(".cardGraph").style.display = "none";
        document.querySelector(".cardGraph2").style.display = "none";
        document.querySelector(".cardGraph3").style.display = "none";
        document.querySelector(".cardGraph4").style.display = "none";
        document.querySelector(".cardGraph5").style.display = "none";
        document.querySelector(".cardGraph6").style.display = "none";
        document.querySelector(".cardGraph7").style.display = "none";
        document.querySelector(".cardGraph8").style.display = "none";
    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/OrderAnalysis`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.brandLists = res.data.brand
                this.divisionLists = res.data.division
                this.$store.dispatch('setOverlay', false)
            })
        },

        currentPeriod() {
            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        title: 'Error!',
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";
            document.querySelector(".cardGraph5").style.display = "none";
            document.querySelector(".cardGraph6").style.display = "none";
            document.querySelector(".cardGraph7").style.display = "none";
            document.querySelector(".cardGraph8").style.display = "none";

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderAnalysis/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    division: this.divisionModel ? this.divisionModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.length != 0){
                    document.querySelector(".cardGraph").style.display = "block";
                    this.renderChart1(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
                
            })

        },

        renderChart1(data1, data2, data3){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                title: {
                    text: "Order Analysis Chart (Click chart bar for details)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Weight in Ton",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.orderSummary,
                        name: "Order Summary",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontColor: "#fff",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#00b7c2",
                        dataPoints: data1
                    },
                    {
                        type: "column",
                        click: this.productionSummary,
                        name: "Production Summary",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontColor: "#fff",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#ee6f57",
                        dataPoints: data2
                    },
                    {
                        type: "column",
                        click: this.shipmentSummary,
                        name: "Shipment Summary",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontColor: "#fff",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#132743",
                        dataPoints: data3
                    },
                ]
            })

            chart1.render()
        
        },

        renderChart2(judul, data1, data2, data3, data4, data5, data6, data7){
            
            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Brand`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "#0.##",
                        indexLabel: "{label} #percent%",
                        indexLabelFontSize: 12,
                        dataPoints: data1
                    },
                ]
            })

            chart2.render()

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Class/Division`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "##.##",
                        indexLabel: "{label} #percent%",
                        indexLabelFontSize: 12,
                        dataPoints: data2
                    },
                ]
            })

            chart3.render()

            var chart4 = new CanvasJS.Chart("chartContainer4", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Customer`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "##.##",
                        indexLabel: "{label} #percent%",
                        indexLabelFontSize: 12,
                        dataPoints: data3
                    },
                ]
            })

            chart4.render()

            var chart5 = new CanvasJS.Chart("chartContainer5", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Thickness`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "##.##",
                        indexLabel: "{label} {y} T",
                        indexLabelFontSize: 12,
                        dataPoints: data4
                    },
                ]
            })

            chart5.render()

            var chart6 = new CanvasJS.Chart("chartContainer6", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Jenis`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "##.##",
                        indexLabel: "{label} {y} T",
                        indexLabelFontSize: 12,
                        dataPoints: data5
                    },
                ]
            })

            chart6.render()

            var chart7 = new CanvasJS.Chart("chartContainer7", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Alamat Kirim`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "##.##",
                        indexLabel: "{label} {y} T",
                        indexLabelFontSize: 12,
                        dataPoints: data6
                    },
                ]
            })

            chart7.render()

            var chart8 = new CanvasJS.Chart("chartContainer8", {
                animationEnabled: true,
                title: {
                    text: `${judul} by Distributor`,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                data: [
                    {
                        type: "pie",
                        percentFormatString: "##.##",
                        indexLabel: "{label} {y} T",
                        indexLabelFontSize: 12,
                        dataPoints: data7
                    },
                ]
            })

            chart8.render()

        },

        async orderSummary(e){
            console.log('order summary');
            console.log(e);
            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";
            document.querySelector(".cardGraph5").style.display = "none";
            document.querySelector(".cardGraph6").style.display = "none";
            document.querySelector(".cardGraph7").style.display = "none";
            document.querySelector(".cardGraph8").style.display = "none";

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderAnalysis/orderSummary`, { 
                    mill: this.millModel ? this.millModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    division: this.divisionModel ? this.divisionModel : '',
                    periode: e.dataPoint.label,
            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                document.querySelector(".cardGraph2").style.display = "block";
                document.querySelector(".cardGraph3").style.display = "block";
                document.querySelector(".cardGraph4").style.display = "block";
                document.querySelector(".cardGraph5").style.display = "block";
                document.querySelector(".cardGraph6").style.display = "block";
                document.querySelector(".cardGraph7").style.display = "block";
                document.querySelector(".cardGraph8").style.display = "block";
                this.renderChart2("Order Analysis", res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4, res.data.arr_list5, res.data.arr_list6, res.data.arr_list7)

            })

        },

        async productionSummary(e){
            console.log('production summary');
            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";
            document.querySelector(".cardGraph5").style.display = "none";
            document.querySelector(".cardGraph6").style.display = "none";
            document.querySelector(".cardGraph7").style.display = "none";
            document.querySelector(".cardGraph8").style.display = "none";

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderAnalysis/productionSummary`, { 
                    mill: this.millModel ? this.millModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    division: this.divisionModel ? this.divisionModel : '',
                    periode: e.dataPoint.label,
            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                document.querySelector(".cardGraph2").style.display = "block";
                document.querySelector(".cardGraph3").style.display = "block";
                document.querySelector(".cardGraph4").style.display = "block";
                document.querySelector(".cardGraph5").style.display = "block";
                document.querySelector(".cardGraph6").style.display = "block";
                document.querySelector(".cardGraph7").style.display = "block";
                document.querySelector(".cardGraph8").style.display = "block";
                this.renderChart2("Production Analysis", res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4, res.data.arr_list5, res.data.arr_list6, res.data.arr_list7)

            })

        },

        async shipmentSummary(e){
            console.log('Shipment sum');
            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";
            document.querySelector(".cardGraph4").style.display = "none";
            document.querySelector(".cardGraph5").style.display = "none";
            document.querySelector(".cardGraph6").style.display = "none";
            document.querySelector(".cardGraph7").style.display = "none";
            document.querySelector(".cardGraph8").style.display = "none";

            // axios({
            //     method: 'post',
            //     url: '/user/12345',
            //     data: {
            //         firstName: 'Fred',
            //         lastName: 'Flintstone'
            //     }
            //     });

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/OrderAnalysis/shipmentSummary`, { 
                    mill: this.millModel ? this.millModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    division: this.divisionModel ? this.divisionModel : '',
                    periode: e.dataPoint.label,
            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } ,
            
            },
            {timeout: 4800000}
            )
            .then(res => {

                this.$store.dispatch('setOverlay', false)

                document.querySelector(".cardGraph2").style.display = "block";
                document.querySelector(".cardGraph3").style.display = "block";
                document.querySelector(".cardGraph4").style.display = "block";
                document.querySelector(".cardGraph5").style.display = "block";
                document.querySelector(".cardGraph6").style.display = "block";
                document.querySelector(".cardGraph7").style.display = "block";
                document.querySelector(".cardGraph8").style.display = "block";
                this.renderChart2("Shipment Analysis", res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4, res.data.arr_list5, res.data.arr_list6, res.data.arr_list7)

            })

        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
            }

        }

    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        /* background: linear-gradient(to right, #cdb4db, #a2d2ff) !important; */
        color: #fff;
    }

    .bgCustom2 {
        /* background: #bde0fe !important; */
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>